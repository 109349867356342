@font-face {
    font-family: 'Proxima Nova';
    /* src: url('../fonts/proximanova-bold.ttf') format('truetype'); */
    src: url('../fonts/proximanova-bold-webfont.eot?#iefix') format('embedded-opentype'),
        url('../fonts/proximanova-bold-webfont.woff') format('woff'),
        url('../fonts/proximanova-bold.ttf') format('truetype'),
        url('../fonts/proximanova-bold-webfont.svg') format('svg');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'Proxima Nova';
    /* src: url('../fonts/proximanova-light.ttf') format('truetype'); */
    src: url('../fonts/proximanova-light-webfont.eot') format('embedded-opentype'),
        url('../fonts/proximanova-light-webfont.woff') format('woff'),
        url('../fonts/proximanova-light.ttf') format('truetype'),
        url('../fonts/proximanova-light-webfont.svg') format('svg');
    font-weight: normal;
    font-style: normal;
}

:root {
    --real-estate-ternary-color: #EF484C !important;
    --real-estate-heading-font-size: 50px !important;
    --real-estate-max-width: 1340px !important;
    --real-estate-sides-padding: 40px !important;
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    margin: 0;
}

::-ms-input-placeholder {
    /* Edge 12-18 */
    color: #ccc;
    font-size: 20px;
}

::placeholder {
    color: #ccc;
    font-size: 20px;
}

/* ----- navbar ----- */
.realestate-navbar-wrapper {
    display: flex;
    align-items: center;
    width: 100%;
    height: var(--navbar-height);
    background: var(--secondary-color);
    overflow: hidden;
    position: sticky;
    top: 0;
    z-index: 1;
    transition: all 200ms ease;
    font-family: 'Proxima Nova', sans-serif !important;
}

.realestate-navbar-wrapper.realestate-navbar-shadow {
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, .1);
}

.realestate-navbar-wrapper>.realestate-navbar-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: auto;
    width: 100%;
    max-width: var(--real-estate-max-width);
    padding: 0 var(--real-estate-sides-padding);
}

.realestate-navbar-wrapper>.realestate-navbar-container>.realestate-navbar-logo {
    height: 50px;
    cursor: pointer;
}

.realestate-navbar-wrapper>.realestate-navbar-container>.realestate-navbar-wrapper-items {
    display: flex;
    align-items: center;
    gap: 8px;
}

.realestate-navbar-wrapper>.realestate-navbar-container>.realestate-navbar-wrapper-items>.realestate-hamburger-menu {
    width: 20px;
    height: 20px;
    cursor: pointer;
}

/* ----- home ----- */
.realestate-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    margin: auto;
    background-color: #fff;
    font-family: 'Proxima Nova', sans-serif !important;
}

.realestate-container>section {
    display: flex;
    min-height: calc(100vh - var(--navbar-height));
    max-width: var(--real-estate-max-width);
    padding: 0 var(--real-estate-sides-padding);
    place-content: space-between;
}

.realestate-container>section.section-container {
    position: relative;
    width: 100%;
    align-items: center;
    gap: 40px;
}

.realestate-container>section.section-container>.content {
    display: flex;
    flex-direction: column;
    justify-content: end;
    gap: 10px;
    width: 50%;
}

.realestate-container>section.section-container>.content>.heading {
    font-size: var(--real-estate-heading-font-size);
    overflow-wrap: anywhere;
    font-family: 'Proxima Nova', sans-serif !important;
    letter-spacing: -1px;
}

.realestate-container>section.section-container>.content>.heading>strong {
    font-family: 'Proxima Nova', sans-serif !important;
}

.realestate-container>section.section-container>.content>.paragraph {
    font-size: 20px;
    line-height: 1.4em;
    font-family: 'Proxima Nova', sans-serif !important;
}

.realestate-container>section.section-container>.banner-section {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 50%;
}

.realestate-container>section.section-container>.banner-section>.heading {
    font-size: 90px;
    font-family: 'Proxima Nova', sans-serif !important;
    overflow-wrap: anywhere;
    color: var(--real-estate-ternary-color);
}

.realestate-container>section.section-container>.banner-section>.heading>span {
    font-weight: 100;
    font-family: 'Proxima Nova', sans-serif !important;
}

.realestate-container>section.section-container>.banner-section>.paragraph {
    font-size: 22px;
    font-family: 'Proxima Nova', sans-serif !important;
}

.realestate-container>section.section-container>.call-to-action {
    grid-area: third;
}

.call-to-action {
    cursor: pointer;
    margin: auto;
    padding: 8px 16px;
    background-color: var(--real-estate-ternary-color);
    color: var(--secondary-color);
    text-transform: lowercase;
    font-family: 'Proxima Nova', sans-serif !important;
    border-radius: 50px;
    font-size: 14px;
    text-align: center;
}

.call-to-action:active {
    scale: .95;
}

.realestate-container>section.full-section-container {
    width: 100%;
    /* grid-auto-flow: column dense;
    grid-auto-columns: 1fr;
    grid-auto-rows: 1fr;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1.7fr 0.3fr;
    grid-template-areas:
        "first first"
        "third third"; */
}

.realestate-container>section.full-section-container>.project-launch-container {
    display: flex;
    flex-direction: column;
    place-content: center;
    gap: 50px;
    width: 100%;
}

.realestate-container>section.full-section-container>.call-to-action {
    grid-area: third;
}

.realestate-container>section.full-section-container>.project-launch-container>.heading {
    font-size: var(--real-estate-heading-font-size);
    font-family: 'Proxima Nova', sans-serif !important;
}

.realestate-container>section.full-section-container>.project-launch-container>.heading-content {
    display: flex;
    flex-direction: column;
}

.realestate-container>section.full-section-container>.project-launch-container>.heading-content>.heading {
    font-size: var(--real-estate-heading-font-size);
    font-family: 'Proxima Nova', sans-serif !important;
    text-align: center;
    overflow-wrap: anywhere;
    letter-spacing: -1px;
}

.realestate-container>section.full-section-container>.project-launch-container>.project-launch-holder {
    display: flex;
    justify-content: center;
    gap: 200px;
}

.realestate-container>section.full-section-container>.project-launch-container>.project-launch-holder>.project-launch-item-list {
    display: flex;
    flex-direction: column;
    gap: 14px;
    font-size: 20px;
    font-family: 'Proxima Nova', sans-serif !important;
}

.realestate-container>section.full-section-container>.project-launch-container>.project-launch-holder>.project-launch-item-list>li {
    /* list-style-position: inside; */
    overflow-wrap: anywhere;
    font-family: 'Proxima Nova', sans-serif !important;
}

.realestate-container>section.full-section-container>.project-launch-container>.project-launch-holder>.project-launch-item-list>li::marker {
    font-size: large;
    font-family: 'Proxima Nova', sans-serif !important;
}

.realestate-container>section.full-section-container>.project-launch-container>.project-launch-holder>.project-launch-item-list>li>span {
    font-size: 17px;
    font-family: 'Proxima Nova', sans-serif !important;
}


.realestate-video-container {
    height: auto;
    position: relative;
    grid-auto-columns: 1fr;
    grid-template-rows: 1.7fr 0.3fr;
    gap: 0px 0px;
    place-content: space-between;
}

.realestate-video-container>.banner-video-wrapper {
    height: 100%;
    position: relative;
    align-content: center;
    grid-area: 1 / 1 / 2 / 2;
}

.realestate-video-container>.banner-video-wrapper>video {
    height: max-content;
    object-fit: contain;
    object-position: top;
    outline: none;
}

.realestate-video-container>.call-to-action {
    grid-area: 2 / 1 / 3 / 2;
}

/* ----------- who we are ----------- */

.realestate-who-we-are-container {
    padding: var(--real-estate-sides-padding) var(--real-estate-sides-padding) 0 !important;
    width: 100%;
    flex-direction: column;
    gap: 50px;
    /* grid-auto-columns: 1fr;
    grid-template-columns: 1fr 1fr;
    gap: 50px 0px;
    grid-template-areas:
        "first first"
        "second third"; */
    /* grid-template-columns: 1fr 1fr;
    grid-template-rows: 0.4fr 1.6fr 1fr;
    gap: 50px 0px;
    grid-template-areas:
        "first first"
        "second second"
        "second second"; */
    align-content: space-around;
}

.realestate-who-we-are-container>.brand-container {
    display: flex;
    flex-direction: column;
    gap: 50px;
    /* grid-area: first; */
    justify-content: space-around;
}

.realestate-who-we-are-container>.brand-container>.heading {
    font-size: var(--real-estate-heading-font-size);
    font-family: 'Proxima Nova', sans-serif !important;
    overflow-wrap: anywhere;
}

.realestate-who-we-are-container>.brand-container>.brand-list .rfm-child {}

.realestate-who-we-are-container>.brand-container>.brand-list .rfm-child>img {
    padding: 0 20px;
    height: 70px;
}

.realestate-who-we-are-container>.who-we-are-holder {
    display: flex;
    width: 100%;
    gap: 50px;
    /* grid-area: second;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1.8fr 0.2fr;
    gap: 50px 0px;
    grid-template-areas:
        "section1 section2"
        "section3 section3"; */
}

.realestate-who-we-are-container>.who-we-are-holder>.banner-section {
    width: 50%;
}

.realestate-who-we-are-container>.who-we-are-holder>.banner-section>iframe {
    background: white;
    width: 100%;
    height: 640px;
}

.realestate-who-we-are-container>.who-we-are-holder>.content {
    /* grid-area: section1; */
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 50px;
    width: 50%;
}

.realestate-who-we-are-container>.who-we-are-holder>.content>.heading {
    font-size: var(--real-estate-heading-font-size);
    font-family: 'Proxima Nova', sans-serif !important;
}

.realestate-who-we-are-container>.who-we-are-holder>.content>.paragraph {
    font-size: 20px;
    font-family: 'Proxima Nova', sans-serif !important;
    line-height: 1.4em;
}

/* .realestate-who-we-are-container>.who-we-are-holder>.call-to-action {
    grid-area: section3;
} */

/* ----- contact us ----- */
.realestate-contact-us-container {
    position: fixed;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    background: #fff;
    max-width: 500px;
    z-index: 100;
    width: 90%;
    top: 0;
    right: 0;
    bottom: 0;
    gap: 50px;
    box-shadow: -1px 0px 4px 0 rgba(0, 0, 0, .1);
    transform: translateX(100%);
    transition: all 200ms ease;
    padding: 0 var(--real-estate-sides-padding);
}

.realestate-contact-us-container.swipe-in {
    transform: translateX(0%);
}

.realestate-contact-us-container>.contact-info {
    display: flex;
    flex-direction: column;
}

.realestate-contact-us-container>.contact-info>p, .contact-us-container>.contact-info>a {
    overflow-wrap: anywhere;
}

.realestate-contact-us-container>.contact-info>.heading {
    font-size: x-large;
    font-family: 'Proxima Nova', sans-serif !important;
}

.realestate-contact-us-container>.contact-info>.email-id {
    padding-top: 14px;
}

.realestate-contact-us-container>.contact-info>.address {
    padding-top: 8px;
}

.realestate-contact-us-container>.contact-info>.phone-number,
.realestate-contact-us-container>.contact-info>.email-id,
.realestate-contact-us-container>.contact-info>.address {
    font-size: large;
    font-family: 'Proxima Nova', sans-serif !important;
}

.realestate-contact-us-container>.social-media-info {
    display: flex;
    flex-direction: column;
    gap: 10px;
    font-size: large;
    font-family: 'Proxima Nova', sans-serif !important;
}

.realestate-contact-us-container>.social-media-info>.heading {
    font-family: 'Proxima Nova', sans-serif !important;
}

.realestate-contact-us-container>.social-media-info>.icon-list {
    display: flex;
    gap: 15px;
}

.realestate-contact-us-container>.social-media-info>.icon-list>a {
    width: 26px;
    height: 26px;
}

.realestate-contact-us-container>.close-icon {
    position: absolute;
    width: 25px;
    height: 25px;
    top: calc(var(--navbar-height) / 2);
    right: var(--real-estate-sides-padding);
    cursor: pointer;
}

.popup-outer {
    position: fixed;
    inset: 0;
    background: #000000ba;
    z-index: -1;
    opacity: 0;
    transition: all 150ms ease;
}

.popup-outer.pop-in {
    opacity: 1;
    z-index: 1;
}

.realestate-user-data-container {
    position: fixed;
    display: flex;
    flex-direction: column;
    justify-content: center;
    background: #fff;
    z-index: 100;
    width: calc(100% - var(--real-estate-sides-padding));
    top: 50%;
    left: 50%;
    gap: 12px;
    box-shadow: 0 0 4px 0 rgba(0, 0, 0, .1);
    transition: all 150ms ease;
    padding: 20px;
    border-radius: 4px;
    max-width: 350px;
    visibility: hidden;
    transform: translate(-50%, -30%);
    opacity: 0;
}

.realestate-user-data-container>.user-data-container-heading {
    font-size: 32px;
    line-height: 30px;
}

.realestate-user-data-container>.user-data-container-desc {
    font-size: 14px;
    line-height: 20px;
    opacity: .8;
}

.realestate-user-data-container.pop-in {
    transition: all 200ms ease-out;
    transform: translate(-50%, -50%);
    opacity: 1;
    visibility: visible;
}

.realestate-user-data-container>input {
    border: none;
    outline: none;
    border-radius: 2px;
    background-color: #f5f5f5;
    padding: 12px;
    font-size: 20px;
    font-family: 'Proxima Nova', sans-serif !important;
}

.realestate-user-data-container>button.btn {
    display: flex;
    justify-content: center;
    cursor: pointer;
    margin: auto;
    padding: 8px 0px;
    border: none;
    border-radius: 2px;
    outline: none;
    background-color: var(--real-estate-ternary-color);
    width: 100%;
    color: var(--secondary-color);
    text-transform: uppercase;
    font-family: 'Proxima Nova', sans-serif !important;
    margin-top: 20px;
}

.realestate-user-data-container>div.btn:hover {
    opacity: .85;
}

.realestate-user-data-container>div.btn:active {
    scale: .95;
}

.user-form-done-submit {
    position: fixed;
    top: 50%;
    left: 50%;
    z-index: 999;
    display: flex;
    background: var(--real-estate-ternary-color);
    padding: 16px;
    border-radius: 8px;
    color: #fff;
    font-size: 20px;
    letter-spacing: 1px;
    width: max-content;
    flex-direction: column;
    text-align: center;
    max-width: calc(100% - var(--real-estate-sides-padding));
    transform: translate(-50%, -30%);
    opacity: 0;
    visibility: hidden;
    transition: all 200ms ease-out;
}

.user-form-done-submit.pop-in {
    transform: translate(-50%, -50%);
    opacity: 1;
    visibility: visible;
}

@media only screen and (max-width:1200px) {

    .realestate-container>section.section-container>.content>.heading br,
    .realestate-container>section.section-container>.content>.paragraph br {
        display: none;
    }

    .realestate-container>section.full-section-container>.project-launch-container>.project-launch-holder {
        gap: 130px;
    }

    .realestate-who-we-are-container>.who-we-are-holder>.banner-section>iframe {
        height: 600px;
    }
}

@media only screen and (max-width:1100px) {

    .realestate-who-we-are-container>.who-we-are-holder>.content,
    .realestate-who-we-are-container>.who-we-are-holder>.banner-section {
        width: 100%;
    }

    .realestate-who-we-are-container>.who-we-are-holder {
        display: flex;
        flex-direction: column;
        gap: 50px 0px;
    }

    .realestate-who-we-are-container>.who-we-are-holder>.content>.paragraph br {
        display: none;
    }

    .realestate-who-we-are-container>.who-we-are-holder>.call-to-action {
        margin-bottom: 30px;
    }

    .realestate-who-we-are-container>.content>.paragraph br {
        display: none;
    }

    .realestate-who-we-are-container>.who-we-are-holder>.banner-section>iframe {
        height: 900px;
    }

    .realestate-container>section.full-section-container>.project-launch-container>.project-launch-holder {
        padding: 0 calc(var(--real-estate-sides-padding) * 2);
    }
}

@media only screen and (max-width:1000px) {

    .realestate-who-we-are-container>.who-we-are-holder>.banner-section>iframe {
        height: 840px;
    }
}

@media only screen and (max-width:950px) {

    .realestate-who-we-are-container>.who-we-are-holder>.banner-section>iframe {
        height: 800px;
    }
}

@media only screen and (max-width:967px) {
    .realestate-container {
        gap: 25px;
    }

    .realestate-container>section.section-container>.content,
    .realestate-container>section.section-container>.banner-section {
        width: 100%;
    }

    .realestate-container>section.full-section-container>.project-launch-container>.project-launch-holder {
        flex-direction: column;
        gap: 14px;
    }

    .realestate-container>section.section-container {
        display: flex;
        flex-direction: column;
        min-height: auto;
        padding: var(--real-estate-sides-padding);
    }

    .realestate-container>section.section-container>.call-to-action {
        margin: var(--real-estate-sides-padding) 0;
    }

    .realestate-container>section.full-section-container {
        min-height: auto;
        padding: var(--real-estate-sides-padding);
    }

    .realestate-container>section.full-section-container>.call-to-action,
    .realestate-video-container>.call-to-action {
        /* margin: var(--real-estate-sides-padding) auto; */
    }

    .realestate-container>section {
        min-height: auto;
        padding: var(--real-estate-sides-padding);
    }

    .realestate-video-container>.banner-video-wrapper>video {
        height: 100%;
    }
}

@media only screen and (max-width:900px) {

    .realestate-who-we-are-container>.who-we-are-holder>.banner-section>iframe {
        height: 750px;
    }
}

@media only screen and (max-width:850px) {

    .realestate-who-we-are-container>.who-we-are-holder>.banner-section>iframe {
        height: 730px;
    }
}

@media only screen and (max-width:800px) {

    .realestate-who-we-are-container>.who-we-are-holder>.banner-section>iframe {
        height: 700px;
    }
}

@media only screen and (max-width:768px) {

    :root {
        --real-estate-heading-font-size: 40px !important;
        --real-estate-sides-padding: 28px !important;
    }

    .realestate-who-we-are-container {
        padding: calc(var(--real-estate-sides-padding) * 2) var(--real-estate-sides-padding) 0 !important;
        gap: 60px;
    }

    .realestate-container>section.section-container,
    .realestate-container>section.full-section-container

    /* .realestate-container>section  */
        {
        padding: calc(var(--real-estate-sides-padding) * 2) var(--real-estate-sides-padding) !important;
    }

    .realestate-container>section.section-container,
    .realestate-container>section.full-section-container {
        padding-top: var(--real-estate-sides-padding) !important;
    }

    .realestate-video-container {
        gap: 20px;
    }

    .realestate-who-we-are-container>.brand-container>.brand-list .rfm-child>img {
        height: 50px;
        padding: 0 10px;
    }

    .user-form-done-submit {
        font-size: 14px;
    }
}

@media only screen and (max-width:750px) {

    .realestate-who-we-are-container>.who-we-are-holder>.banner-section>iframe {
        height: 690px;
    }
}

@media only screen and (max-width:700px) {

    .realestate-who-we-are-container>.who-we-are-holder>.banner-section>iframe {
        height: 660px;
    }
}

@media only screen and (max-width:650px) {

    .realestate-who-we-are-container>.who-we-are-holder>.banner-section>iframe {
        height: 620px;
    }
}

@media only screen and (max-width:600px) {

    .realestate-who-we-are-container>.who-we-are-holder>.banner-section>iframe {
        height: 590px;
    }
}

@media only screen and (max-width:550px) {

    .realestate-who-we-are-container>.who-we-are-holder>.banner-section>iframe {
        height: 560px;
    }
}

@media only screen and (max-width:520px) {

    .realestate-who-we-are-container>.who-we-are-holder>.banner-section>iframe {
        height: 540px;
    }
}

@media only screen and (max-width:500px) {

    .realestate-who-we-are-container>.who-we-are-holder>.banner-section>iframe {
        height: 510px;
    }
}

@media only screen and (max-width:450px) {

    .realestate-who-we-are-container>.who-we-are-holder>.banner-section>iframe {
        height: 470px;
    }
}

@media only screen and (max-width:400px) {

    .realestate-who-we-are-container>.who-we-are-holder>.banner-section>iframe {
        height: 440px;
    }

    .call-to-action {
        font-size: 10px;
    }
}

@media only screen and (max-width:350px) {

    .realestate-who-we-are-container>.who-we-are-holder>.banner-section>iframe {
        height: 410px;
    }

    .user-form-done-submit {
        font-size: 12px;
        padding: 16px 8px;
    }
}

@media only screen and (max-width:300px) {

    .realestate-who-we-are-container>.who-we-are-holder>.banner-section>iframe {
        height: 380px;
    }
}