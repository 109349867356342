@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
@import url(@splidejs/react-splide/css);
@import url(@splidejs/react-splide/css/sea-green);
@import url(@splidejs/react-splide/css/core);

img, video {
    max-width: 100%;
    max-height: 100%;
    display: flex;
}

*, .cursor {
    padding: 0
}

*, img {
    user-select: none
}

* {
    margin: 0;
    box-sizing: border-box;
    font-family: Poppins, sans-serif
}

:root {
    --primary-color: #000;
    --secondary-color: #fff;
    --ternary-color: #f7c683;
    --navbar-height: 80px;
    --max-width: 1440px;
    --sides-padding: 40px;
    --tops-padding: 90px;
    --bottom-padding: 60px;
    --heading-font-size: 20px;
    --medium-font-size: 16px;
    --small-font-size: 14px
}

::-webkit-scrollbar {
    width: 5px
}

::-webkit-scrollbar-track {
    background: var(--primary-color)
}

::-webkit-scrollbar-thumb {
    background: var(--secondary-color);
    border-radius: 4px
}

::-webkit-scrollbar-thumb:hover {
    background: #95a5a696
}

html {
    scroll-behavior: smooth !important
}

body {
    background-color: var(--primary-color);
    /* line-height: 1.5; */
}

body.no-scroll {
    overflow: hidden
}

img {
    object-fit: contain
}

a {
    text-decoration: none;
    color: inherit
}

.App {
    position: relative
}

.cursor {
    pointer-events: none;
    position: fixed;
    width: 15px;
    height: 15px;
    background-color: var(--secondary-color);
    border-radius: 50%;
    mix-blend-mode: difference;
    transition: transform .3s, width .25s, height .25s;
    transform: translate(-7.5px, -7.5px);
    z-index: 99
}

.heading-title {
    height: 60px;
    align-self: center;
    padding: 0 5%;
    width: 100%
}

@media (max-width:768px) {
    :root {
        --sides-padding: 16px
    }
}