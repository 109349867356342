.balcony-content-container {
    display: flex;
    flex-direction: column;
    padding-top: var(--tops-padding)
}

.balcony-container {
    position: relative;
    background-color: var(--secondary-color)
}

.icons-container, .icons-container>.item-icon-holder>.item-icon>.image-static {
    position: absolute
}

.balcony-container>.balcony-holder {
    position: relative;
    display: flex;
    justify-content: space-between;
    max-width: var(--max-width);
    margin: auto;
    height: 100%
}

.balcony-animation {
    object-position: top;
    object-fit: contain;
    height: 500px;
    display: flex;
    place-self: center;
    z-index: 4
}

.balcony-animation.balcony-girl-animation {
    left: 0
}

.balcony-animation.balcony-boy-animation {
    right: 0
}

.icons-container {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    place-content: space-between;
    place-items: center;
    grid-gap: 10px;
    width: calc(100% - 500px);
    margin: auto;
    z-index: 10;
    top: 70%;
    left: 50%;
    transform: translate(-50%, -50%)
}

.icons-container>.item-icon-holder {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 175px
}

.icons-container>.item-icon-holder:last-child {
    width: 110px
}

.icons-container>.item-icon-holder>.item-icon {
    position: absolute;
    top: -75px;
    display: flex;
    height: 80px;
    object-fit: contain;
    aspect-ratio: 1;
    justify-content: center
}

.icons-container>.item-icon-holder:last-child>.item-icon {
    height: 100px;
    top: -90px
}

.icons-container>.item-icon-holder>.item-icon>img {
    aspect-ratio: 1
}

.icons-container>.item-icon-holder:hover>.item-icon>.image-static {
    opacity: 0
}

.icons-container>.item-icon-holder>.item-count {
    font-size: var(--heading-font-size);
    font-weight: 500;
    padding-top: 26px
}

.icons-container>.item-icon-holder>.item-description {
    font-size: var(--small-font-size);
    text-align: center;
    font-weight: 500;
    padding: 10px 0
}

/* Marquee */
.marquee-container {
    position: absolute !important;
    top: 0;
    width: 100% !important;
    z-index: 5;
    display: flex;
    background: var(--ternary-color);
    padding: 8px;
    gap: 8px;
    font-size: var(--small-font-size);
    font-weight: 600;
    overflow: hidden
}

.marquee-container>.rfm-marquee, .marquee-container>.rfm-marquee>.rfm-initial-child-container {
    gap: 12px
}

.marquee-container li {
    user-select: none
}

@media (max-width:1150px) {
    .icons-container {
        grid-template-columns: 1fr 1fr;
        row-gap: 100px
    }

    .balcony-animation.balcony-boy-animation {
        align-self: flex-start
    }

    .balcony-animation.balcony-boy-animation, .balcony-animation.balcony-girl-animation {
        margin-top: 200px
    }
}

@media (max-width:850px) {
    .icons-container {
        grid-template-columns: 1fr;
        top: 55%
    }

    .balcony-animation.balcony-girl-animation {
        margin-top: 650px;
        object-position: bottom
    }

    .balcony-animation {
        width: 25%
    }
}

@media (max-width:967px) {
    .icons-container>.item-icon-holder>.item-icon>.image-static {
        display: none
    }
}

@media (max-width:720px) {
    .icons-container {
        grid-template-columns: 1fr;
        top: 60%
    }

    .icons-container>.item-icon-holder {
        width: auto
    }

    .balcony-animation {
        height: 400px
    }

    .balcony-animation.balcony-girl-animation {
        margin-top: 620px
    }
}

@media (max-width:500px) {
    .balcony-animation.balcony-girl-animation {
        margin-top: 750px
    }

    /* .elevation-title {
        width: max-content
    } */
}

@media (max-width:450px) {
    .balcony-animation.balcony-girl-animation {
        margin-top: 700px
    }
}