:root {
    --packaging-ternary-color: #EF484C !important;
    --packaging-heading-font-size: 50px !important;
    --packaging-max-width: 1340px !important;
    --packaging-sides-padding: 40px !important;
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    margin: 0;
}

::-ms-input-placeholder {
    /* Edge 12-18 */
    color: #ccc;
    font-size: 20px;
}

::placeholder {
    color: #ccc;
    font-size: 20px;
}

.packaging-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    margin: auto;
    background-color: #fff;
    font-family: 'Proxima Nova', sans-serif !important;
    gap: 40px;
    padding-bottom: 40px;
}

.packaging-container>section {
    /* display: flex;
    min-height: calc(100vh - var(--navbar-height));
     max-width: var(--packaging-max-width);
    padding: 0 var(--packaging-sides-padding);
    place-content: space-between; */
    max-width: var(--packaging-max-width);
    padding: 0 var(--packaging-sides-padding);
}

.packaging-container>.packaging-video-container {
    display: flex;
    height: auto;
    position: relative;
    gap: 0px;
    min-height: auto;
}

.packaging-container>.welcome-heading {
    font-size: 20px;
    max-width: var(--packaging-max-width);
    padding: 0 var(--packaging-sides-padding);
    text-align: center;
}

.text-content {
    /* padding: var(--packaging-sides-padding) calc(var(--packaging-sides-padding)* 1.5); */
    font-size: 16px;
    text-align: center;
}

.packaging-custom-pick-product {
    display: flex;
    gap: 20px;
    align-items: center;
}

.packaging-custom-pick-product>span {
    color: var(--packaging-ternary-color);
    font-size: 60px;
    font-weight: 600;
}

.packaging-custom-pick-product>div {
    display: flex;
    flex-direction: column;
    font-size: 20px;
    text-align: left;
}

.packaging-count-grid {
    display: flex;
    gap: 80px;
}

.packaging-count-grid>div {
    display: flex;
    flex-direction: column;
    align-items: center;
    /* width: min-content; */
}

.packaging-count-grid>div>span {
    font-size: 16px;
    font-weight: 500;
    text-align: center;
}

.packaging-count-grid>div>.item-count {
    color: var(--packaging-ternary-color);
    font-weight: 600;
    font-size: 24px;
    text-align: left;
}

.fleur-colonie-holder {
    padding: 0 calc(var(--packaging-sides-padding) * 2) !important;
}

.packaging-footer {
    align-items: flex-start !important;
    padding-top: 40px !important;
}

.packaging-footer>.text-content {
    text-align: justify;
}

.double-layout {
    display: grid !important;
    gap: 40px;
    grid-template-columns: 1fr 1fr;
    /* padding-top: var(--packaging-sides-padding) !important; */
    align-items: center;
}

.double-layout>img {
    /* padding: calc(var(--packaging-sides-padding) / 2); */
    border: 0 solid #ccc;
}

.triple-layout {
    display: grid !important;
    gap: 20px;
    grid-template-columns: 1fr 1fr 1fr;
    /* padding-top: var(--packaging-sides-padding) !important; */
}

.overlay-block {
    position: relative;
    transition: all 200ms ease;
    overflow: hidden;
}

.overlay-block:hover>.overlay {
    opacity: 1;
}

.overlay-block:hover>.overlay-text {
    transform: translateY(0px);
}

.overlay-block>.overlay {
    position: absolute;
    inset: 0;
    /* background: linear-gradient(0deg, rgb(0 0 0 / 50%) 0%, rgb(255 255 255 / 0%) 90%); */
    opacity: 0;
    transition: all 100ms ease;
}

.overlay-text {
    position: absolute;
    bottom: 10px;
    left: 10px;
    font-size: 24px;
    font-weight: 500;
    color: var(--secondary-color);
    letter-spacing: 1px;
    transform: translateY(150%);
    transition: all 200ms ease;
}

.packaging-container>.call-to-action {
    font-size: 20px;
}

@media only screen and (max-width:1100px) {
    .text-content br {
        display: none;
    }
}

@media only screen and (max-width:967px) {
    .double-layout {
        display: flex !important;
        flex-direction: column;
    }

    .fleur-colonie-holder {
        padding: 0 var(--packaging-sides-padding) !important;
    }

    .packaging-count-grid>div>span br {
        display: none;
    }

    .packaging-count-grid {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        gap: 30px;
    }
}

@media only screen and (max-width:768px) {

    :root {
        --real-estate-heading-font-size: 40px !important;
        --real-estate-sides-padding: 28px !important;
    }

    .packaging-container {
        gap: 25px;
    }

    .packaging-container>.welcome-heading {
        font-size: 16px;
    }

    .text-content {
        font-size: 14px;
        text-align: center !important;
    }

    .packaging-custom-pick-product>span {
        font-size: 36px;
    }

    .packaging-custom-pick-product>div {
        font-size: 12px;
    }

    .packaging-count-grid {
        grid-template-columns: 1fr;
    }

    .packaging-count-grid>div>span {
        font-weight: 400;
    }

    .packaging-count-grid>div>span br {
        display: flex;
    }

    .packaging-count-grid>div>.item-count {
        font-size: 18px;
    }

    .triple-layout {
        display: flex !important;
        flex-direction: column;
    }

    .packaging-custom-pick-product {
        text-align: center;
    }

    .packaging-footer {
        padding-top: 0 !important;
    }
}

@media only screen and (max-width:650px) {
    .packaging-count-grid {
        display: flex;
        flex-direction: column;
    }
}