.banner-container {
    position: relative;
    height: calc(100vh - var(--navbar-height))
}

.banner-container>.banner-video-icon {
    position: fixed;
    width: 50px;
    height: 50px;
    background-color: #f5f5f5;
    z-index: 3;
    right: 20px;
    padding: 14px;
    border-radius: 50%;
    opacity: 1;
    top: calc(100% - var(--navbar-height))
}

.home-banner-video-wrapper {
    position: relative;
    height: 100%
}

.home-banner-video-wrapper>video {
    object-fit: cover;
    object-position: top
}

@media only screen and (max-width:967px) {
    .banner-container {
        height: unset
    }
}

@media only screen and (max-width:767px) {
    .grid-layout-holder {
        padding: 0 var(--sides-padding);
        grid-gap: 30px
    }
}

@media only screen and (max-width:600px) {
    .grid-layout-holder>.grid-item>.item-description {
        position: relative;
        transform: unset
    }

    .grid-layout-holder {
        grid-gap: 5%;
    }

    .grid-layout-holder>.grid-item:nth-child(1),
    .grid-layout-holder>.grid-item:nth-child(2),
    .grid-layout-holder>.grid-item:nth-child(4),
    .grid-layout-holder>.grid-item:nth-child(5) {
        padding: 14px 0;
    }
}