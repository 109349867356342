.solution-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: var(--secondary-color);
    gap: 50px;
    padding: var(--tops-padding) var(--sides-padding) var(--bottom-padding) var(--sides-padding);
    place-content: flex-start;
    min-height: 50vh
}

.solution-item-column-holder {
    display: flex;
    width: 100%;
    max-width: var(--max-width);
    justify-content: space-between;
    gap: 25px;
    min-height: 300px;
    position: relative
}

.solution-column-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 16px 8px;
    gap: 24px;
    transition: .2s;
    border-radius: 8px;
    flex: 1;
    filter: grayscale(1)
}

.solution-column-item.desktop-view:hover {
    transform: translateY(-10px);
    box-shadow: rgb(0 0 0 / 10%) 0 4px 4px
}

.solution-column-item:hover {
    filter: grayscale(0)
}

.solution-column-item>.solution-column-item-image {
    width: 80px;
    height: 80px;
    position: relative
}

.solution-column-item>.solution-column-item-image>img {
    position: absolute
}

.solution-column-item:hover>.solution-column-item-image>.image-static {
    opacity: 0
}

.solution-column-item>.solution-column-item-heading {
    font-size: var(--small-font-size);
    font-weight: 600;
}

.solution-column-item>.solution-column-item-content {
    display: flex;
    flex-direction: column;
    gap: 12px;
    font-size: var(--small-font-size);
    font-weight: 500;
}

.splide {
    padding: 0 !important
}

.splide__arrow svg {
    fill: var(--primary-color) !important
}

.splide__arrow--prev {
    left: 0 !important
}

.splide__arrow--next {
    right: 0 !important
}

.solution-item-column-holder.mobile-view {
    display: none
}

@media (max-width:967px) {
    .solution-column-item {
        filter: grayscale(0)
    }

    .solution-item-column-holder.desktop-view {
        display: none
    }

    .solution-item-column-holder.mobile-view {
        display: flex
    }

    .solution-column-item>.solution-column-item-content {
        text-align: center
    }
}