.grid-layout-holder {
    column-count: 3;
    width: 100%;
    grid-gap: 60px;
    max-width: 1640px
}

.grid-layout-holder>.grid-item {
    position: relative;
    gap: 12px;
    cursor: pointer;
    filter: grayscale(1)
}

.grid-layout-holder>.grid-item>.grid-item-image-holder {
    position: relative
}

.grid-layout-holder>.grid-item>.grid-item-image-holder>.grid-item-play-icon {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 25%;
    height: 25%;
    opacity: .8;
    transition: .25s
}

.grid-layout-holder>.grid-item:hover {
    filter: grayscale(0)
}

.grid-layout-holder>.grid-item:hover>.grid-item-image-holder>.grid-item-play-icon {
    opacity: 0
}

.grid-layout-holder>.grid-item>.item-video {
    transition: 1s
}

.grid-layout-holder>.grid-item>.item-video.active {
    z-index: 10
}

.grid-layout-holder>.grid-item>.item-description {
    font-size: var(--small-font-size);
    padding: 0 5%;
    display: inline-block;
    position: absolute;
    bottom: 0;
    left: 0;
    font-weight: 300;
    transform: translateY(100%)
}

.good-ideas-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: var(--primary-color);
    color: var(--secondary-color);
    gap: 40px;
    padding: 120px 0 60px;
    place-content: flex-start
}

.video-player-wrapper {
    display: flex;
    visibility: hidden;
    opacity: 0;
    position: fixed;
    z-index: 90;
    inset: 0;
    width: 100%;
    height: 100%;
    background-color: #000000d6;
    justify-content: center;
    align-items: center;
    transition: opacity .2s, display .2s, visibility .2s
}

.video-player-wrapper.active {
    visibility: visible;
    opacity: 1
}

.video-player-wrapper>.video-player-overlay {
    position: absolute;
    inset: 0
}

.video-player-wrapper>.video-player {
    width: 90%;
    height: 90%;
    transform: translateY(-150%);
    transition: transform .2s
}

.video-player-wrapper.active>.video-player {
    transform: translateY(0)
}

.video-player-wrapper>.video-player>video {
    object-fit: contain;
    outline: 0;
    padding: 16px;
    background: #000
}

.video-close-icon {
    position: absolute;
    width: 40px;
    height: 40px;
    z-index: 90;
    top: 10px;
    right: 10px
}

@media only screen and (max-width:967px) {
    .good-ideas-container {
        padding: 80px 0 60px
    }

    .grid-layout-holder>.grid-item>.item-description {
        padding: 0 5%
    }

    .grid-layout-holder {
        column-count: 2
    }
}