.navbar-container {
    display: flex;
    align-items: center;
    height: var(--navbar-height);
    justify-content: space-between;
    position: sticky;
    top: 0;
    z-index: 50;
    overflow: hidden;
    background: var(--primary-color);
    padding: 0 var(--sides-padding)
}

.navbar-container>.navbar-holder {
    display: flex;
    max-width: var(--max-width);
    width: 100%;
    height: 100%;
    margin: auto;
    justify-content: space-between;
    align-items: center;
    z-index: 99;
    mix-blend-mode: difference
}

.navbar-hamburger-menu {
    display: none;
    position: absolute;
    right: var(--sides-padding);
    width: 35px;
    height: 35px;
    transform: translateX(100px);
    color: var(--secondary-color);
    cursor: pointer
}

.navbar-logo {
    height: 50px
}

.navbar-menu-items-holder {
    display: flex;
    list-style: none;
    gap: 38px;
    font-size: var(--medium-font-size);
    text-transform: lowercase;
    font-weight: 300;
    color: var(--secondary-color);
    flex-wrap: wrap;
    user-select: none;
    transition: .1s;
    outline: 0
}

.navbar-menu-items-holder>.navbar-menu-item:hover {
    transition: scale .1s;
    scale: 1.05 !important
}

.navbar-hamburger-menu-circular-overlay {
    position: fixed;
    background-color: #000;
    right: var(--sides-padding);
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    transition: clip-path .5s
}

.line {
    fill: none;
    stroke: var(--secondary-color);
    stroke-width: 6;
    transition: stroke-dasharray .6s cubic-bezier(.4, 0, .2, 1), stroke-dashoffset .6s cubic-bezier(.4, 0, .2, 1)
}

.line1, .line3 {
    stroke-dasharray: 60 207;
    stroke-width: 6
}

.line2 {
    stroke-dasharray: 60 60;
    stroke-width: 6
}

.navbar-hamburger-menu.active .line1, .navbar-hamburger-menu.active .line3 {
    stroke-dasharray: 90 207;
    stroke-dashoffset: -134;
    stroke-width: 6
}

.navbar-hamburger-menu.active .line2 {
    stroke-dasharray: 1 60;
    stroke-dashoffset: -30;
    stroke-width: 6
}

@media only screen and (max-width:968px) {
    .navbar-hamburger-menu {
        display: flex;
        animation: .8s .5s forwards bounce-in-right
    }

    .navbar-menu-items-holder>.navbar-menu-item:first-child {
        animation: 1s cubic-bezier(.075, .82, .165, 1) forwards fadeOut_menu_items;
        animation-delay: .1s
    }

    .navbar-menu-items-holder>.navbar-menu-item:nth-child(2) {
        animation: 1s cubic-bezier(.075, .82, .165, 1) forwards fadeOut_menu_items;
        animation-delay: .2s
    }

    .navbar-menu-items-holder>.navbar-menu-item:nth-child(3) {
        animation: 1s cubic-bezier(.075, .82, .165, 1) forwards fadeOut_menu_items;
        animation-delay: .3s
    }

    .navbar-menu-items-holder>.navbar-menu-item:nth-child(4) {
        animation: 1s cubic-bezier(.075, .82, .165, 1) forwards fadeOut_menu_items;
        animation-delay: .4s
    }

    .navbar-menu-items-holder>.navbar-menu-item:nth-child(5) {
        animation: 1s cubic-bezier(.075, .82, .165, 1) forwards fadeOut_menu_items;
        animation-delay: .5s
    }
}

@keyframes fadeOut_menu_items {
    0% {
        transform: translateX(0);
        opacity: 1
    }

    95% {
        transform: translateX(100px);
        opacity: 0
    }

    100% {
        display: none;
        opacity: 0
    }
}

@keyframes bounce-in-right {
    0% {
        opacity: 0;
        transform: translateX(2000px)
    }

    60% {
        opacity: 1;
        transform: translateX(-30px)
    }

    80% {
        transform: translateX(10px)
    }

    100% {
        transform: translateX(0)
    }
}

.mobile-menubar-popup-container {
    width: 100%;
    height: 100%;
    color: var(--secondary-color);
    position: fixed;
    inset: 0;
    transform: translateX(150%);
    transition: .5s;
    z-index: 5;
    opacity: 0
}

.mobile-menubar-popup-container.active {
    transform: translateX(0);
    opacity: 1
}

.mobile-menubar-items-holder {
    padding-top: var(--navbar-height);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
    list-style: none;
    font-size: 30px;
    font-weight: 300;
    gap: 16px
}

.mobile-menubar-items-holder>.mobile-menubar-item {
    overflow-wrap: anywhere;
    text-align: center
}