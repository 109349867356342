.hut-window-holder>.hut-window-bottom-image, .hut-window-holder>.hut-window-top-image {
    border: 2px solid var(--secondary-color);
    aspect-ratio: 1;
    background-color: var(--primary-color);
    padding: 6px
}

.roof-container {
    display: flex;
    background-color: var(--primary-color);
    padding: var(--tops-padding) var(--sides-padding) var(--bottom-padding) var(--sides-padding);
    background-image: url(../images/bg_2.svg)
}

.roof-container>.roof-holder {
    display: flex;
    max-width: 1440px;
    margin: auto;
    gap: 40px
}

.hut-container {
    display: flex;
    flex-direction: column;
    width: 50%;
    gap: 12px;
    margin: auto
}

.hut-window-holder {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(2, 1fr);
    grid-column-gap: 30px;
    grid-row-gap: 30px;
    width: 85%;
    margin: auto;
    perspective: 1000px
}

.hut-window-holder>div {
    display: flex;
    transform: skewX(.001deg);
    transition: .35s;
    transform-style: preserve-3d;
    perspective-origin: 50% 50%;
    will-change: transform;
    transform-origin: top center
}

.hut-window-holder>.hut-window-top-image {
    grid-area: 1/1/2/2;
    border-radius: 2px
}

.hut-window-holder>.hut-door>img, .hut-window-holder>.hut-window-bottom-image>img, .hut-window-holder>.hut-window-top-image>img {
    -webkit-filter: grayscale(1);
    filter: grayscale(1);
    backface-visibility: hidden;
    webkit-backface-visibility: hidden;
    object-fit: cover
}

.hut-window-holder>.hut-door>img:hover, .hut-window-holder>.hut-window-bottom-image>img:hover, .hut-window-holder>.hut-window-top-image>img:hover {
    -webkit-filter: grayscale(0);
    filter: grayscale(0)
}

.hut-window-holder>.hut-window-bottom-image {
    grid-area: 2/1/3/2;
    border-radius: 2px;
    align-self: flex-end
}

.hut-window-holder>.hut-door {
    grid-area: 1/2/3/3;
    background-color: var(--primary-color);
    border: 2px solid var(--secondary-color);
    padding: 6px;
    border-radius: 2px
}

.pets-container {
    display: flex;
    flex-direction: column;
    width: 50%;
    align-items: center;
    justify-content: space-between
}

.pets-content {
    display: flex;
    flex-direction: column;
    align-self: flex-start;
    color: var(--secondary-color);
    gap: 28px;
    width: 70%
}

.pets-content>.description {
    font-size: var(--small-font-size)
}

.pets-container>.pet-bird-house {
    width: 250px;
    display: flex;
    align-self: flex-end
}

.pets-container>.cat-rolling {
    width: 400px;
    display: flex;
    align-self: flex-end
}

@media only screen and (max-width:967px) {
    .roof-container>.roof-holder {
        flex-direction: column;
        gap: 50px
    }

    .hut-container {
        width: 100%
    }

    .pets-container {
        width: 100%;
        gap: 30px
    }

    .pets-content {
        align-self: unset;
        width: 90%
    }

    /* .pets-content>.elevation-title {
        width: fit-content;
        margin: auto
    } */

    .pets-content>.description {
        text-align: center
    }

    .pets-container>.pet-bird-house {
        align-self: unset;
        width: 30%
    }

    .pets-container>.cat-rolling {
        align-self: unset;
        width: 50%
    }
}

@media only screen and (max-width:600px) {
    .hut-window-holder {
        grid-column-gap: 15px;
        grid-row-gap: 15px
    }
}

@media only screen and (max-width:500px) {
    .hut-window-holder {
        grid-column-gap: 10px;
        grid-row-gap: 10px
    }

    .hut-window-holder>.hut-door, .hut-window-holder>.hut-window-bottom-image, .hut-window-holder>.hut-window-top-image {
        border-width: 1.5px;
        padding: 3px
    }
}

@media only screen and (max-width:350px) {
    .hut-window-holder {
        grid-column-gap: 10px;
        grid-row-gap: 10px
    }
}