.footer-container {
    display: flex;
    background-color: var(--secondary-color);
    padding: 0 var(--sides-padding) 0 var(--sides-padding)
}

.footer-container>.footer-holder {
    position: relative;
    display: flex;
    max-width: var(--max-width);
    margin: auto;
    gap: 4px;
    width: 100%;
    padding: var(--tops-padding) var(--sides-padding) var(--bottom-padding) var(--sides-padding)
}

.footer-container>.footer-holder>.footer-graphic {
    display: flex;
    width: 65%;
    margin: auto
}

.footer-container>.footer-holder>.footer-graphic>.you-and-us-image {
    width: 125px;
    align-self: flex-start;
    position: absolute;
    top: 0;
    left: var(--sides-padding)
}

.footer-container>.footer-holder>.footer-graphic>.coffee-table-image {
    width: inherit;
    display: flex;
    margin: 0 auto;
    align-self: flex-end;
    padding: 0 60px
}

.footer-container>.footer-holder>.footer-content {
    display: flex;
    align-self: flex-end;
    flex-direction: column;
    width: 35%;
    padding: 24px
}

.footer-content>.get-in-touch-text {
    font-size: var(--heading-font-size);
    font-weight: 500;
}

.footer-content>.footer-content-description-1 {
    font-size: var(--small-font-size);
    padding-top: 6px
}

.footer-content>.footer-content-description-layout {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(3, 1fr);
    grid-column-gap: 0;
    grid-row-gap: 12px;
    padding: 24px 0
}

.footer-content>.footer-content-description-layout>div {
    display: flex;
    flex-direction: column;
    font-size: var(--small-font-size)
}

.footer-content>.footer-content-description-layout>.partners-info {
    grid-area: 1/1/2/3
}

.footer-content>.footer-content-description-layout>.opportunities-info {
    grid-area: 2/1/3/3
}

.footer-content>.footer-content-description-layout>.address-info-1 {
    grid-area: 3/1/4/2
}

.footer-content>.footer-content-description-layout>.address-info-1.mobile-view {
    display: none
}

.footer-content>.footer-content-description-layout>.opportunities-info>a, .footer-content>.footer-content-description-layout>.partners-info>a {
    font-weight: 600;
    line-break: anywhere
}

.footer-content>.social-icons {
    display: flex;
    gap: 8px
}

.footer-content>.social-icons>a {
    width: 28px;
    height: 28px
}

.instagram-icon, .linkedin-icon {
    transition: .2s
}

.linkedin-icon:hover {
    fill: #0077b5
}

.instagram-icon:hover {
    fill: #FCAF45
}

.contact-coffee-illustration {
    position: absolute;
    bottom: 15px;
    left: 50%;
    max-width: 60%;
    width: 100%;
    transform: translate(-50%, 0);
}

@media only screen and (max-width:1300px) {
    .footer-container>.footer-holder>.footer-content {
        width: 450px
    }

    .footer-container>.footer-holder {
        gap: 40px
    }

    .footer-graphic {
        width: 100% !important;
        padding-left: 130px
    }
}

@media only screen and (max-width:967px) {
    .footer-content>.footer-content-description-layout>.address-info-1.desktop-view {
        display: none
    }

    .footer-content>.footer-content-description-layout>.address-info-1.mobile-view {
        display: flex
    }

    .footer-content>.footer-content-description-layout {
        display: flex;
        flex-direction: column
    }

    .footer-container>.footer-holder {
        flex-direction: column-reverse
    }

    .footer-container>.footer-holder>.footer-content {
        width: calc(100% - 50px);
        padding: 0;
        align-self: flex-start
    }

    .footer-graphic {
        padding-left: 0;
        width: 90% !important
    }

    .footer-container>.footer-holder>.footer-graphic>.you-and-us-image {
        left: unset;
        right: 0;
        width: 60px
    }

    .footer-container>.footer-holder>.footer-graphic>.coffee-table-image {
        padding: 0
    }
}

@media only screen and (max-width:350px) {
    .footer-container>.footer-holder>.footer-content {
        padding-top: 80px;
        width: 100%
    }

    .footer-container>.footer-holder>.footer-graphic>.you-and-us-image {
        left: unset;
        transform: translateX(-50%)
    }
}