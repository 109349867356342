.pre-loader-overlay {
    position: fixed;
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    z-index: 99
}

.pre-loader-animation-holder {
    position: absolute;
    width: 20%;
    height: 20%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    align-items: center;
    justify-content: center
}

.pre-loader-overlay-holder {
    background-color: var(--primary-color);
    height: -webkit-fill-available
}

.pre-loader-overlay-holder>svg path {
    fill: var(--primary-color)
}