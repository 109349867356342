.welcome-container {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-image: url(../images/bg_1.svg);
    gap: 40px;
    padding: var(--tops-padding) var(--sides-padding) var(--tops-padding) var(--sides-padding);
    overflow: hidden
}

.welcome-container>.welcome-content {
    text-align: center;
    color: var(--secondary-color);
    font-weight: 400;
    font-size: var(--small-font-size)
}

.welcome-container>.welcome-content.mobile-view {
    display: none
}

@media only screen and (max-width:500px) {
    .welcome-container>.welcome-content.desktop-view {
        display: none
    }

    .welcome-container>.welcome-content.mobile-view {
        display: flex
    }
}

.shooting-stars>.star {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 4px;
    height: 4px;
    background: #fff;
    border-radius: 50%;
    box-shadow: 0 0 0 4px rgba(255, 255, 255, .1), 0 0 0 8px rgba(255, 255, 255, .1), 0 0 20px #fff;
    animation: 3s linear infinite animate;
    display: flex
}

.shooting-stars>.star::before {
    content: '';
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: 100px;
    height: 1px;
    background: linear-gradient(90deg, #fff, transparent)
}

@keyframes animate {
    0% {
        transform: rotate(315deg) translateX(0);
        opacity: 1
    }

    70% {
        opacity: 1
    }

    100% {
        transform: rotate(315deg) translateX(-1500px);
        opacity: 0
    }
}

.shooting-stars>.star:first-child {
    top: 0;
    right: 0;
    left: initial;
    animation-delay: 0s;
    animation-duration: 4s
}

.shooting-stars>.star:nth-child(2) {
    top: 0;
    right: 20%;
    left: initial;
    animation-delay: .2s;
    animation-duration: 3s
}

.shooting-stars>.star:nth-child(3) {
    top: 80;
    right: 40%;
    left: initial;
    animation-delay: .4s;
    animation-duration: 2s
}

.shooting-stars>.star:nth-child(4) {
    top: 0;
    right: 60%;
    left: initial;
    animation-delay: .6s;
    animation-duration: 1.5s
}

.shooting-stars>.star:nth-child(5) {
    top: 0;
    right: 80%;
    left: initial;
    animation-delay: .8s;
    animation-duration: 2.5s
}